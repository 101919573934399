<script>
import moment from "moment/moment";
import Swal from "sweetalert2";
import store from "@/state/store";
import {getFileNameUrl} from "@/helpers/api-config";

export default {
  name : 'Modal',
  props : ["modalData"],
  data() {
    return {
      data : this.modalData,
      showModal : false,
    }
  },
  mounted() {
//    console.log(this.modalData)
    this.showModal = true;
  },
  methods: {
    openModal() {
      window.history.pushState({}, '', '#modal');
      window.onpopstate = history.onpushstate = () => {
        this.showModal = false
      }
    },

    closeModal() {
      this.$emit('close');
      window.history.back()
    },

    fileDownloadUrl(type, uid, name, date) {
      let typeName = '';
      let buttonClass = ''
      switch (type) {
        case 'AUDIO':
          typeName = '음성';
          buttonClass = 'btn-outline-primary'
          break;
        case 'FRONT':
          typeName = '전면';
          buttonClass = 'btn-outline-secondary'
          break;
        case 'PHOTO':
          typeName = '후면';
          buttonClass = 'btn-outline-success'
          break;
        case 'VIDEO':
          typeName = '영상';
          buttonClass = 'btn-outline-danger'
          break;
      }
      let fileName = name + '구조요청' + (typeName ? ' '+ typeName : '') +'('+ moment(date).format('YYYYMMDDHHmm') +')'

      if (typeName) {
        return '<a href="'+ getFileNameUrl(uid, fileName) +'" class="btn btn-sm '+ buttonClass +' btn-rounded spacing-5">'+ typeName +'</a>';
      } else {
        return '';
      }
    },

    // 삭제
    deleteData(id) {
      Swal.fire({
        text: "증거자료 요청을 삭제하시겠습니까?",
        icon: "warning",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestDeleteData(id)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 삭제 서버요청
    requestDeleteData(id) {
      let type = "evidences"
      let params = { 'id' : id }

      if (params.id) {
        store.dispatch("apiInquiry/erase", { type, params }).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              this.$emit('loadData');
              document.querySelector("#btn-cancel").click();
            }
          });
        });
      }
    },

    // 상태변경
    statusUpdate(id, state) {
      let stateName = "요청"
      switch (state) {
        case "Approve":
          stateName = "승인";
          break;
        case "Rejection":
          stateName = "반려";
          break;
      }
      Swal.fire({
        text: "증거자료 요청을 "+ stateName +"하시겠습니까?",
        icon: "warning",
        confirmButtonText: "변경",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestStatusUpdate(id, state)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 상태변경 서버요청
    requestStatusUpdate(id, state) {
      let type = "evidences"
      let params = {}
      if (id) {
        store.dispatch("apiInquiry/statusUpdate", { type, id, state, params }).then((result) => {
          Swal.fire({
            text: result.message,
            icon: result.success ? "success" : "error",
            confirmButtonText: "확인",
          }).then(result => {
            if (result.value) {
              this.$emit('loadData');
              document.querySelector("#btn-cancel").click();
            }
          });
        });
      }
    },
  }
}
</script>

<template>
  <b-modal
      v-model="showModal"
      id="modal-box"
      @show="openModal"
      @hidden="closeModal"
      centered
      title="상세정보"
      title-class="font-16 fw-bold"
      hide-footer
  >
    <div class="row mb-2 align-items-center">
      <div class="mb-2">
        <div class="mb-1 fw-semibold">신청인</div>
        <div class="form-control bg-light">{{ data.name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">신청인 연락처</div>
        <div class="form-control bg-light">{{ data.phone }}&nbsp;</div>
      </div>
      <div class="mb-2">
        <div class="mb-1 fw-semibold">경찰서</div>
        <div class="form-control bg-light">{{ data.police_office }}&nbsp;</div>
      </div>
      <div class="mb-2">
        <div class="mb-1 fw-semibold">경찰관</div>
        <div class="form-control bg-light">{{ data.police_name }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">경찰관 연락처</div>
        <div class="form-control bg-light">{{ data.police_phone }}&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">요청사유</div>
        <div class="form-control bg-light" v-html="data.tag_content"></div>
      </div>
      <div class="my-2">
        <div class="mb-2 fw-semibold">파일</div>
        <div class="form-control bg-light pt-2">
          <div v-if="data.assets_count">
            <span v-for="asset in data.help.assets" :key="asset.uid" class="pe-1" v-html="fileDownloadUrl(asset.type, asset.uid, data.name, data.help.created_at)"></span>
          </div>
          <span v-else>&nbsp;</span>
        </div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">상태</div>
        <div class="form-control bg-light"><span class="fw-bold" :class="data.status_class">{{ data.status_message }}</span>&nbsp;</div>
      </div>
      <div class="my-2">
        <div class="mb-1 fw-semibold">요청일시</div>
        <div class="form-control bg-light">{{ data.created_at }}&nbsp;</div>
      </div>
      <div class="my-2 text-center">
          <b-button v-if="data.status == 'Request'" variant="primary" class="btn-rounded mx-1" @click="statusUpdate(data.uid, 'Approve')">승인</b-button>
          <b-button v-if="data.status == 'Request'" variant="warning" class="btn-rounded mx-1" @click="statusUpdate(data.uid, 'Rejection')">반려</b-button>
          <b-button v-if="data.status == 'Rejection'" variant="primary" class="btn-rounded mx-1" @click="statusUpdate(data.uid, 'Approve')">승인</b-button>
          <b-button v-if="data.status == 'Approve'" variant="warning" class="btn-rounded mx-1" @click="statusUpdate(data.uid, 'Rejection')">반려</b-button>
          <b-button variant="danger" class="btn-rounded mx-1" @click="deleteData(data.uid)">삭제</b-button>
          <b-button variant="secondary" class="btn-rounded mx-1" id="btn-cancel" data-bs-dismiss="modal">닫기</b-button>
      </div>
    </div>
  </b-modal>
</template>
