<script>
import Layout from "../../../../layouts/main";
import PageHeader from "@/components/page-header";
import Pagination from "@/components/widgets/pagination";

import Tablesaw from 'tablesaw'
import {reloadTablesaw} from "@/helpers/datatable";

import store from "@/state/store";
import moment from "moment";
import Loader from "@/components/widgets/loader";
import Modal from "@/components/modals/evidence-modal";
import {getFileNameUrl} from '@/helpers/api-config';
import Swal from "sweetalert2";

// 검색 기본값
const BASE_SEARCH = {
  dateField: 'created_at',
  field: 'fixedcrypt:name',
  keyword: '',
  startDate : '',
  endDate : '',
  period : 7,
  status : '',
  page: 1,
}

export default {
  components: {
    Layout,
    PageHeader,
    Pagination,
    Loader,
    Modal
  },

  data() {
    return {
      menuId: 300200,
      menuCode: 'evidences',
      currentPage: 1,
      totalCount: 0,
      listData: [],
      isPageLoading: false,
      datepicker: {
        startDate : null,
        endDate : null,
      },
      search: BASE_SEARCH,
      // 검색키워드 항목
      fields: [
        { value: 'fixedcrypt:name', text: '신청인' },
        { value: 'fixedcrypt:phone', text: '신청인 연락처' },
        { value: 'police_office', text: '경찰서' },
        { value: 'fixedcrypt:police_name', text: '경찰관' },
        { value: 'fixedcrypt:police_phone', text: '경찰관 연락처' },
        { value: 'content', text: '요청사유' },
      ],
      periods: [
        { value: 7, text: '1주일' },
        { value: 1, text: '1개월' },
        { value: 3, text: '3개월' },
        { value: 6, text: '6개월' },
        { value: 0, text: '전체' }
      ],
      states: [
        { value: 'all', text: '전체' },
        { value: 'Request', text: '요청' },
        { value: 'Approve', text: '승인' },
        { value: 'Rejection', text: '반려' },
      ],
      showModal: false,
      modalData: {},
    };
  },
  created() {
    this.$changeMenu(this.menuId, this.menuCode)
  },
  mounted () {
    Tablesaw._init();
  },
  beforeRouteUpdate(to, from, next) {
    if(to.fullPath !== from.fullPath) {
      this.initSearch(to.query)
      next();
    }
  },
  methods: {
    onRoutechange() {
      this.initSearch(this.$route.query)
      this.loadData()
    },

    // 데이터 추출
    loadData() {
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = ""
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = ""
      }

      let page = this.search.page

      // 검색 조건 결합
      let params = {
        ...this.$route.query,
        ...this.search,
        page: page
      };
      // console.log(params);

      let type = this.menuCode
      this.isPageLoading = true
      store.dispatch("apiInquiry/list", { type, params }).then((result) => {
        this.updateTableData(result.data, result.recordsFiltered)
        this.isPageLoading = false
      }, () => {
        this.isPageLoading = false
      })
    },

    // 데이터 가공
    updateTableData(data, total) {
      this.listData = data;
      this.totalCount = total;

      this.$nextTick(function () {
        // Tablesaw가 동적으로 처리되지 않아 별도 처리
        reloadTablesaw(this.$refs.tablesaw)
      })
    },

    // 라우트 변경 ( 브라우져 뒤로가기 구현 )
    changeRouter() {
      // 검색조건에 검색기간 설정
      this.searchDate()

      if (!moment(this.search.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.startDate = "all"
      }

      if (!moment(this.search.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.search.endDate = "all"
      }

      let page = this.search.page
      let params = { page: page };

      // 쿼리스트링 결합
      let query = {
        ...this.$route.query,
        ...this.search,
        ...params
      };

      // 히스토리 추가
      this.$router.push({ query })
    },

    // 페이징 처리
    changePage(page) {
      if (this.search.page != page) {
        this.currentPage = this.search.page = page
        this.changeRouter()
      }
    },

    // 검색 초기값 설정
    initSearch(routeQuery) {
      if (routeQuery.startDate != "all" && !moment(routeQuery.startDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.startDate = new Date(moment().add(-7, 'days').format('YYYY-MM-DD'))
      }
      if (routeQuery.endDate != "all" && !moment(routeQuery.endDate, 'YYYY-MM-DD', true).isValid()) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      }

      this.search = Object.assign({}, {...BASE_SEARCH, ...routeQuery})
      this.search.page = this.search.page * 1
      this.currentPage = this.search.page

      // 라우트가 변경되면 검색기간 변경
      if (this.search.startDate) {
        this.datepicker.startDate = new Date(this.search.startDate)
      }
      if (this.search.endDate) {
        this.datepicker.endDate = new Date(this.search.endDate)
      }
    },

    // 검색 실행
    searchData() {
      this.currentPage = this.search.page = 1

      // 라우트가 변경되면 loadData 호출
      this.changeRouter()
    },

    // 검색기간 설정
    searchDate() {
      if (this.datepicker.startDate) {
        this.search.startDate = moment(this.datepicker.startDate).format('YYYY-MM-DD')
      } else {
        this.search.startDate = "all"
      }

      if (this.datepicker.endDate) {
        this.search.endDate = moment(this.datepicker.endDate).format('YYYY-MM-DD')
      } else {
        this.search.endDate = "all"
      }
    },

    // 단위별 검색기간 변경
    // 전체:0, 일주일:7, 월단위:1,3,6,9,12, 일단위:12~
    searchPeriod(period) {
      this.search.period = period
      if (period > 0) {
        this.datepicker.endDate = new Date(moment().format('YYYY-MM-DD'))
      } else {
        this.search.endDate = "all"
        this.datepicker.endDate = ""
      }
      if (period == 7 || period > 12) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'days').format('YYYY-MM-DD'))
      } else if (period) {
        this.datepicker.startDate = new Date(moment().add(period*(-1), 'months').format('YYYY-MM-DD'))
      } else {
        this.search.startDate = "all"
        this.datepicker.startDate = ""
      }
      this.searchData()
    },

    // 현재 설정된 검색기간 스타일 변경
    variantPeriod(period) {
      if (period === 7 && !this.$route.query.period) {
        return "info"
      } else {
        return (period == this.$route.query.period) ? "info" : "outline-secondary"
      }
    },

    // 상태 검색
    searchStatus(status) {
      this.search.status = status
      this.searchData()
    },

    // 현재 설정된 상태 스타일 변경
    variantStatus(status) {
      if (this.search.status == status || (status == "all" && !this.search.status)) {
        return "info"
      } else {
        return "outline-secondary"
      }
    },

    fileDownloadUrl(type, uid, name, date) {
      let typeName = '';
      let buttonClass = ''
      switch (type) {
        case 'AUDIO':
          typeName = '음성';
          buttonClass = 'btn-outline-primary'
          break;
        case 'FRONT':
          typeName = '전면';
          buttonClass = 'btn-outline-secondary'
          break;
        case 'PHOTO':
          typeName = '후면';
          buttonClass = 'btn-outline-success'
          break;
        case 'VIDEO':
          typeName = '영상';
          buttonClass = 'btn-outline-danger'
          break;
      }
      let fileName = name + '구조요청' + (typeName ? ' '+ typeName : '') +'('+ moment(date).format('YYYYMMDDHHmm') +')'

      if (typeName) {
        return '<a href="'+ getFileNameUrl(uid, fileName) +'" class="btn btn-sm '+ buttonClass +' btn-rounded spacing-5" target="_blank">'+ typeName +'</a>';
      } else {
        return '';
      }
    },

    openModal: function(event) {
      this.modalData = event;
    },

    // 삭제
    deleteData(id) {
      Swal.fire({
        text: "증거자료 요청을 삭제하시겠습니까?",
        icon: "warning",
        confirmButtonText: "삭제",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestDeleteData(id)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 삭제 서버요청
    requestDeleteData(id) {
      let type = this.menuCode
      let params = { 'id' : id }

      if (params.id) {
        store.dispatch("apiInquiry/erase", { type, params }).then((result) => {
          Swal.fire(result.message, "", result.success ? "success" : "error");
          this.loadData()
        });
      }
    },

    // 상태변경
    statusUpdate(id, state) {
      let stateName = "요청"
      switch (state) {
        case "Approve":
          stateName = "승인";
          break;
        case "Rejection":
          stateName = "반려";
          break;
      }
      Swal.fire({
        text: "증거자료 요청을 "+ stateName +"하시겠습니까?",
        icon: "warning",
        confirmButtonText: "변경",
        cancelButtonText: "취소",
        showCancelButton: true
      }).then(result => {
        if (result.value) {
          this.requestStatusUpdate(id, state)
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          console.log('cancel')
        }
      });
    },

    // 상태변경 서버요청
    requestStatusUpdate(id, state) {
      let type = this.menuCode
      let params = {}
      if (id) {
        store.dispatch("apiInquiry/statusUpdate", { type, id, state, params }).then((result) => {
          Swal.fire(result.message, "", result.success ? "success" : "error");
          this.loadData()
        });
      }
    },
  },
  watch: {
    $route: {
      handler: "onRoutechange",
      immediate: true,
      deep: true,
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader />
    <Modal v-if="showModal" :modal-data="modalData" @close="showModal = false" @loadData=loadData />
    <div class="row">
      <div class="col-lg-12">
        <div class="card min-content">
          <div class="card-body">
            <div>
              <b-form class="px-2" @submit.prevent="searchData">
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">진행상태</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="state in states"
                          :key="state.value"
                          :variant="variantStatus(state.value)"
                          @click="searchStatus(state.value)"
                          class="spacing-10 me-1"
                      >{{ state.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-2 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">요청일 검색</label>
                  <div class="col-sm input-group">
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.startDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 pe-2 mb-1 search-date">
                      <date-picker
                          input-class="form-control"
                          v-model:value="datepicker.endDate"
                          value-type="date"
                      ></date-picker>
                    </div>
                    <div class="col-sm-2 w-auto text-nowrap">
                      <b-button
                          v-for="period in periods"
                          :key="period.value"
                          :variant="variantPeriod(period.value)"
                          @click="searchPeriod(period.value)"
                          class="spacing-10 me-1"
                      >{{ period.text }}</b-button>
                    </div>
                  </div>
                </div>
                <div class="form-group row mb-3 pb-2 search-line">
                  <label class="col-form-label col-sm-2 text-nowrap">키워드 검색</label>
                  <div class="col-sm input-group text-nowrap">
                    <b-form-select
                        for="field"
                        v-model="search.field"
                        :options="fields"
                        class="form-control"
                        style="width:125px;flex:none"
                    ></b-form-select>
                    <b-form-input
                        for="keyword"
                        v-model="search.keyword"
                        class="form-control"
                    ></b-form-input>
                  </div>
                </div>
                <div class="row justify-content-between pb-3">
                  <div class="col">
                    <b-button type="submit" variant="primary">검색하기</b-button>
                  </div>
                </div>
              </b-form>
              <div class="position-relative" style="min-height: 130px;">
                <table ref="tablesaw" class="tablesaw table align-middle table-nowrap table-hover" data-tablesaw-mode="columntoggle">
                  <thead class="table-light">
                  <tr>
                    <th scope="col" class="wd-50" data-tablesaw-priority="6">순번</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="persist">신청인</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="5">경찰서</th>
                    <th scope="col" class="wd-80" data-tablesaw-priority="2">경찰관</th>
                    <th scope="col" class="text-start" data-tablesaw-priority="persist">요청사유</th>
                    <th scope="col" class="wd-50" data-tablesaw-priority="3">파일</th>
                    <th scope="col" class="wd-50" data-tablesaw-priority="persist">상태</th>
                    <th scope="col" class="wd-100" data-tablesaw-priority="6">요청일시</th>
                    <th scope="col" class="wd-50" data-tablesaw-priority="4">관리</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="list in listData" :key="list.uid">
                    <td>{{ list.rownum }}</td>
                    <td>
                      <a href="javascript:void(0);" @click="openModal(list), (showModal = true)">
                        <span>{{ list.name }}</span>
                        <img v-if="list.user.social_type" :src="require(`@/assets/images/social/icon_${list.user.social_type.toLowerCase()}.png`)" class="align-text-top" height="16" />
                        <div><span>{{ list.phone }}</span></div>
                      </a>
                    </td>
                    <td>{{ list.police_office ? list.police_office : '-'}}</td>
                    <td>
                      <span>{{ list.police_name }}</span>
                      <div><span>{{ list.police_phone }}</span></div>
                    </td>
                    <td class="text-start ellipsis">
                      <span>{{ list.content }}</span>
                    </td>
                    <td>
                      <div v-if="list.assets_count">
                      <span v-for="asset in list.help.assets" :key="asset.uid" class="ps-1" v-html="fileDownloadUrl(asset.type, asset.uid, list.name, list.help.created_at)"></span>
                      </div>
                    </td>
                    <td><span class="fw-bold" :class="list.status_class">{{ list.status_message }}</span></td>
                    <td>{{ list.register_date }}</td>
                    <td>
                      <b-button v-if="list.status == 'Request'" size="sm" variant="outline-primary" class="btn-rounded spacing-5 me-1" @click="statusUpdate(list.uid, 'Approve')">승인</b-button>
                      <b-button v-if="list.status == 'Request'" size="sm" variant="outline-warning" class="btn-rounded spacing-5 me-1" @click="statusUpdate(list.uid, 'Rejection')">반려</b-button>
                      <b-button v-if="list.status == 'Rejection'" size="sm" variant="outline-primary" class="btn-rounded spacing-5 me-1" @click="statusUpdate(list.uid, 'Approve')">승인</b-button>
                      <b-button v-if="list.status == 'Approve'" size="sm" variant="outline-warning" class="btn-rounded spacing-5 me-1" @click="statusUpdate(list.uid, 'Rejection')">반려</b-button>
                      <b-button size="sm" variant="outline-danger" class="btn-rounded spacing-5" @click="deleteData(list.uid)">삭제</b-button>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="position-absolute base-list-message pt-5" v-show="!isPageLoading && listData.length === 0">조회된 내역이 없습니다.</div>
                <Loader :loading="isPageLoading" class="position-absolute base-list-loader mt-5"></Loader>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <Pagination :total="totalCount" v-model:page="currentPage" @changePage="changePage($event)"></Pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
